import { InMemoryCache } from '@apollo/client';

// to use union in fragments
export const possibleTypes = {
    OpsParameter: ['BooleanOpsParameter', 'NumericalOpsParameter'],
    RitualGame: ['QuizRitualGame', 'WantedRitualGame'],
    SetupInstruction: [
        'CheckTemperatureInstruction',
        'CheckBoxTemperatureInstruction',
        'HotDrinksInstruction',
        'InventoryInstruction',
        'MicrowaveInstruction',
        'MoveProductsInstruction',
        'PhotosInstruction',
        'RemoveProductsInstruction',
        'StoreProductsInstruction',
        'TheftNoticeInstruction',
    ],
    SupplyOrderProductIssue: [
        'BadExpiryDateIssue',
        'BrokenQuantityIssue',
        'DifferentPackagingIssue',
        'DifferentQuantityIssue',
        'NewBarcodeIssue',
        'NotReceivedIssue',
        'UnknownProductIssue',
        'WrongProductIssue',
    ],
};

export const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
        BoxProduct: { keyFields: ['boxId', '_id'] },
        ProductStockVariation: { keyFields: ['supplyOrderId', 'productId'] },
        SupplyOrderProduct: { keyFields: ['supplyOrderId', '_id'] },
        Query: {
            fields: {
                credits: {
                    keyArgs: false,
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.credits || [];
                        const incomingItems = incoming.credits;
                        const newCredits = [...incomingItems];
                        const newCreditIds = newCredits.map((credit) => readField('_id', credit));
                        existingItems.forEach((item: any) => {
                            if (!newCreditIds.includes(readField('_id', item))) {
                                newCredits.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            credits: newCredits,
                        };
                    },
                },
                catalogProducts: {
                    keyArgs: [
                        'filterString',
                        'stateFilter',
                        'categoryFilter',
                        'subcategoryFilter',
                        'supplierFilter',
                        'tagFilter',
                        'infoFilters',
                    ],
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.products || [];
                        const incomingItems = incoming.products;
                        const newProducts = [...incomingItems];
                        const newProductIds = newProducts.map((product) => readField('_id', product));
                        existingItems.forEach((item: any) => {
                            if (!newProductIds.includes(readField('_id', item))) {
                                newProducts.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            products: newProducts,
                        };
                    },
                },
                payments: {
                    keyArgs: ['stateFilter', 'organizationIdFilter'],
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.payments || [];
                        const incomingItems = incoming.payments;
                        const newPayments = [...incomingItems];
                        const newPaymentIds = newPayments.map((payment) => readField('_id', payment));
                        existingItems.forEach((item: any) => {
                            if (!newPaymentIds.includes(readField('_id', item))) {
                                newPayments.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            payments: newPayments,
                        };
                    },
                },
                stockTransfers: {
                    keyArgs: ['filterString'],
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.stockTransfers || [];
                        const incomingItems = incoming.stockTransfers;
                        const newStockTransfers = [...incomingItems];
                        const newStockTransferIds = newStockTransfers.map((stockTransfer) =>
                            readField('_id', stockTransfer),
                        );
                        existingItems.forEach((item: any) => {
                            if (!newStockTransferIds.includes(readField('_id', item))) {
                                newStockTransfers.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            stockTransfers: newStockTransfers,
                        };
                    },
                },
                supplyOrders: {
                    keyArgs: false,
                    merge: false,
                },
                paginatedFilteredSuppliers: {
                    keyArgs: ['filterString'],
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.suppliers || [];
                        const incomingItems = incoming.suppliers;
                        const newSuppliers = [...incomingItems];
                        const newSuppliersIds = newSuppliers.map((supplier) => readField('_id', supplier));
                        existingItems.forEach((item: any) => {
                            if (!newSuppliersIds.includes(readField('_id', item))) {
                                newSuppliers.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            suppliers: newSuppliers,
                        };
                    },
                },
                transactions: {
                    keyArgs: ['filterString', 'stateFilter', 'userId', 'siteFilter', 'providerFilter'],
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.transactions || [];
                        const incomingItems = incoming.transactions;
                        const newTransactions = [...incomingItems];
                        const newTransactionIds = newTransactions.map((transaction) => readField('_id', transaction));
                        existingItems.forEach((item: any) => {
                            if (!newTransactionIds.includes(readField('_id', item))) {
                                newTransactions.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            transactions: newTransactions,
                        };
                    },
                },
                users: {
                    keyArgs: ['filterString'],
                    merge(existing, incoming, { readField }) {
                        const existingItems = existing?.users || [];
                        const incomingItems = incoming.users;
                        const newUsers = [...incomingItems];
                        const newUserIds = newUsers.map((user) => readField('_id', user));
                        existingItems.forEach((item: any) => {
                            if (!newUserIds.includes(readField('_id', item))) {
                                newUsers.push(item);
                            }
                        });
                        return {
                            ...incoming,
                            users: newUsers,
                        };
                    },
                },
            },
        },
    },
});
