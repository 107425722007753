import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import { SITE_TYPES_LABELS } from 'pages/Sites/constants/types';
import { SITE_STATES_LABELS } from 'pages/Sites/constants/states';
import { SiteState } from 'data/__generated__';

import { ColumnsSectionContainer, SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { TotemInput } from 'components/TotemInput';
import { TotemLabel } from 'components/TotemLabel';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { DetailValue } from 'components/DetailsView/DetailValue';
import { StateHistory } from 'components/DetailsView/StateHistory';
import { TotemSwitch } from 'components/TotemSwitch';
import { DetailLink } from 'components/DetailsView';
import { CopyValue } from 'components/CopyValue';
import { StatusTag } from 'pages/Sites/components/StatusTag';

import { dateFromString, formatDateAsAnniversary, getExtendedFormattedDatetime } from '../../../helpers/dateTimes';
import { generateMetabaseDashboard } from '../utils/generateMetabaseDashboard';
import { UpdatedSiteType } from 'pages/Sites/SiteDetails';

const QRCODE_BASE_URL = 'https://onelink.to/sgv8qn?siteId=';

export const GeneralInfoSection = ({
    siteDetails,
    setUpdatedSiteDetails,
}: {
    siteDetails: UpdatedSiteType;
    setUpdatedSiteDetails: (site: UpdatedSiteType) => void;
}) => {
    const {
        _id: siteId,
        unknownShrinkageBillingThreshold,
        createdAt,
        estimatedUserCount,
        hasExternalSetupProcess,
        isSecured,
        isSecuredWithCoffee,
        launchDate,
        name,
        organizationId,
        organizationName,
        removalDate,
        reassortmentOnboardingStockTargets,
        reassortmentStockTargetRatios,
        shouldAutomaticallyCreateTargetReassortment,
        shouldAutomaticallyCreateManualReassortment,
        shouldHaveTheftNotice,
        shouldModulateForExpired,
        shouldModulateForUnknownLosses,
        state,
        stateHistory,
        totemNumber,
        type,
        useNayaxCardForCoffee,
        updatedAt,
    } = siteDetails;

    const [metabaseDashboardUrls, setMetabaseDashboardUrls] = useState<{
        dashboardUrl: string;
        dashboardUrlWithoutLosses: string;
    } | null>(null);

    const generateDashboardUrls = useCallback(async () => {
        const dashboardUrl = await generateMetabaseDashboard({ siteName: name });
        const dashboardUrlWithoutLosses = await generateMetabaseDashboard({ siteName: name, shouldShowLosses: false });
        setMetabaseDashboardUrls({ dashboardUrl, dashboardUrlWithoutLosses });
    }, []);

    useEffect(() => {
        generateDashboardUrls();
    }, [metabaseDashboardUrls]);

    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    const qrCodeUrl = `${QRCODE_BASE_URL}${siteId}`;

    const stateOptions = [
        { value: SiteState.Regular, label: SITE_STATES_LABELS.Regular },
        { value: SiteState.Archived, label: SITE_STATES_LABELS.Archived },
    ];
    const selectedStateOption = stateOptions.find((option) => option.value === state);

    const mondayInThreeWeeks = new Date();
    mondayInThreeWeeks.setDate(mondayInThreeWeeks.getDate() + 21 - ((mondayInThreeWeeks.getDay() + 6) % 7));
    mondayInThreeWeeks.setHours(0, 0, 0, 0);

    return (
        <SectionContainer title="Informations Générales" isInitiallyOpen>
            <>
                <ColumnsSectionContainer numberOfColumns={2}>
                    <SectionColumn>
                        <CopyValue value={siteId} />
                        <TotemInput
                            label="Nom"
                            value={name}
                            placeholder="Modifier le nom"
                            onChange={(value: string) => {
                                const newSiteDetails = { ...siteDetails, name: value };
                                setUpdatedSiteDetails(newSiteDetails);
                            }}
                            data-test="site-details_name"
                        />
                        <TotemLabel>QR Code</TotemLabel>
                        <QRCodeContainer>
                            <QRCode value={qrCodeUrl} renderAs="canvas" size={200} />
                        </QRCodeContainer>
                        <TotemDatePicker
                            label="Date de lancement"
                            selected={launchDate ? dateFromString(launchDate) : null}
                            onChange={(date) => {
                                if (date && mondayInThreeWeeks > date) {
                                    window.alert(
                                        "Non respect de la règle :\n\nLa date d'ouverture du Magasin doit être renseignée strictement avant le lundi de la semaine en J-14 de l'ouverture pour permettre les achats.\n\nVeuillez valider avec data & supply sinon il n'y aura pas de restocking",
                                    );
                                }
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    launchDate: date ? formatDateAsAnniversary({ dateTime: date }) : null,
                                });
                            }}
                        />
                        <TotemDatePicker
                            label="Date de fermeture définitive"
                            sublabel="Les produits vont être récupérés lors de la dernière livraison à cette date. Veuillez mettre un jour où nous sommes sûr de livrer."
                            shouldAllowPastDates={false}
                            selected={removalDate ? dateFromString(removalDate, true) : null}
                            onChange={(date) =>
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    removalDate: date
                                        ? formatDateAsAnniversary({ dateTime: date, useNewFormat: true })
                                        : null,
                                })
                            }
                        />
                        <TotemSwitch
                            label="Création automatique du réassort par stock cible"
                            checked={shouldAutomaticallyCreateTargetReassortment}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    shouldAutomaticallyCreateTargetReassortment:
                                        !shouldAutomaticallyCreateTargetReassortment,
                                });
                            }}
                        />
                        <TotemSwitch
                            label="Création automatique du réassort manuel"
                            checked={shouldAutomaticallyCreateManualReassortment}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    shouldAutomaticallyCreateManualReassortment:
                                        !shouldAutomaticallyCreateManualReassortment,
                                });
                            }}
                        />
                        <TotemSwitch
                            label="Mettre des écriteaux sur le site"
                            checked={shouldHaveTheftNotice}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    shouldHaveTheftNotice: !shouldHaveTheftNotice,
                                });
                            }}
                        />
                        <TotemSwitch
                            label="Rangement sur site externe (pas de totops)"
                            checked={hasExternalSetupProcess}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    hasExternalSetupProcess: !hasExternalSetupProcess,
                                });
                            }}
                        />
                        <TotemSwitch
                            label="Moduler les stocks cibles en fonction des pertes expirés"
                            checked={shouldModulateForExpired}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    shouldModulateForExpired: !shouldModulateForExpired,
                                });
                            }}
                        />
                        <TotemSwitch
                            label="Moduler les stocks cibles en fonction des démarques inconnues"
                            checked={shouldModulateForUnknownLosses}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    shouldModulateForUnknownLosses: !shouldModulateForUnknownLosses,
                                });
                            }}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <CopyValue
                            label="Dashboard Metabase"
                            value={metabaseDashboardUrls?.dashboardUrl || 'chargement'}
                        />
                        <CopyValue
                            label="Dashboard Metabase (sans les pertes)"
                            value={metabaseDashboardUrls?.dashboardUrlWithoutLosses || 'chargement'}
                        />
                        <DetailValue label="Type" value={SITE_TYPES_LABELS[type]} />
                        <TotemSwitch
                            label="Site Sécurisé (Sensei)"
                            checked={isSecured}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    isSecured: !isSecured,
                                    ...(isSecured && { isSecuredWithCoffee: false }),
                                });
                            }}
                        />
                        <TotemSwitch
                            label="Site avec du café (Nayax)"
                            checked={isSecuredWithCoffee}
                            onChange={() => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    isSecuredWithCoffee: !isSecuredWithCoffee,
                                });
                            }}
                        />
                        {isSecuredWithCoffee && (
                            <TotemSwitch
                                label="Utiliser des badges
                                 Nayax"
                                sublabel="Le QR code s'affiche sur l'app et est scanné par la machine. Sinon l'utilisateur scanne le QR code affiché sur le terminal de paiement"
                                checked={useNayaxCardForCoffee}
                                onChange={() => {
                                    setUpdatedSiteDetails({
                                        ...siteDetails,
                                        useNayaxCardForCoffee: !useNayaxCardForCoffee,
                                    });
                                }}
                            />
                        )}
                        <DetailLink
                            label="Organization"
                            name={organizationName}
                            value={organizationId}
                            path="organization"
                        />
                        <DetailValue label="Totem" value={`#${totemNumber}`} />
                        <TotemInput
                            label="Estimation du nombre d'utilisateurs sur site"
                            value={estimatedUserCount}
                            type="number"
                            step="1"
                            min="0"
                            onChange={(value: string) => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    estimatedUserCount: Number(value),
                                });
                            }}
                            data-test="site-details_estimated-user-count"
                        />
                        <TotemInput
                            label="Facturer la démarque inconnue à partir de (0€ = on facture toute la DI)"
                            value={
                                unknownShrinkageBillingThreshold !== null ? unknownShrinkageBillingThreshold : undefined
                            }
                            onChange={(value: string) => {
                                const formattedValue = value ? parseInt(value) : null;
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    unknownShrinkageBillingThreshold: isNaN(formattedValue as number)
                                        ? null
                                        : formattedValue,
                                });
                            }}
                            data-test="site-details_bill-unknown-shrinkage"
                        />
                        <DetailValue label="Date de création" value={getExtendedFormattedDatetime(createdAt)} />
                        <DetailValue label="Dernière mise à jour" value={getExtendedFormattedDatetime(updatedAt)} />
                        <TotemSelect<SiteState>
                            label="Statut"
                            options={stateOptions}
                            value={selectedStateOption}
                            placeholder="Modifier la valeur"
                            onChange={(option: SelectedOption<SiteState>) => {
                                if (option) {
                                    setUpdatedSiteDetails({ ...siteDetails, state: option.value });
                                }
                            }}
                        />
                        <StateHistory label="Historique de statuts" records={formattedStateHistory} />
                    </SectionColumn>
                </ColumnsSectionContainer>
                <div>
                    <Title>Consommation quotidienne moyenne manuelle (à mettre à la main pour l'onboarding)</Title>
                    <InputsContainer>
                        <TotemInput
                            label="Entrées"
                            type="number"
                            value={reassortmentOnboardingStockTargets.starter}
                            onChange={(value) => {
                                const newStarter = parseInt(value) || 0;
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentOnboardingStockTargets: {
                                        ...siteDetails.reassortmentOnboardingStockTargets,
                                        starter: newStarter,
                                    },
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                        <TotemInput
                            label="Petites faims"
                            type="number"
                            value={reassortmentOnboardingStockTargets.smallBites}
                            onChange={(value) => {
                                const newSmallBites = parseInt(value) || 0;
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentOnboardingStockTargets: {
                                        ...siteDetails.reassortmentOnboardingStockTargets,
                                        smallBites: newSmallBites,
                                    },
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                        <TotemInput
                            label="Plats"
                            type="number"
                            value={reassortmentOnboardingStockTargets.main}
                            onChange={(value) => {
                                const newMain = parseInt(value) || 0;
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentOnboardingStockTargets: {
                                        ...siteDetails.reassortmentOnboardingStockTargets,
                                        main: newMain,
                                    },
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                        <TotemInput
                            label="Désserts"
                            type="number"
                            value={reassortmentOnboardingStockTargets.dessert}
                            onChange={(value) => {
                                const newDessert = parseInt(value) || 0;
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentOnboardingStockTargets: {
                                        ...siteDetails.reassortmentOnboardingStockTargets,
                                        dessert: newDessert,
                                    },
                                });
                            }}
                            step="1"
                            min="0"
                            required={true}
                        />
                    </InputsContainer>
                </div>
                <div>
                    <Title>
                        Ratio de consommation de 0 (rien consommé) à 1 (consommation max) (à mettre à la main pour
                        l'onboarding)
                    </Title>
                    <InputsContainer>
                        <TotemInput
                            label="Lundi"
                            type="number"
                            value={reassortmentStockTargetRatios.monday}
                            onChange={(value) => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentStockTargetRatios: {
                                        ...siteDetails.reassortmentStockTargetRatios,
                                        monday: value,
                                    },
                                });
                            }}
                            min="0"
                            step="0.01"
                            required={true}
                        />
                        <TotemInput
                            label="Mardi"
                            type="number"
                            value={reassortmentStockTargetRatios.tuesday}
                            onChange={(value) => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentStockTargetRatios: {
                                        ...siteDetails.reassortmentStockTargetRatios,
                                        tuesday: value,
                                    },
                                });
                            }}
                            min="0"
                            step="0.01"
                            required={true}
                        />
                        <TotemInput
                            label="Mercredi"
                            type="number"
                            value={reassortmentStockTargetRatios.wednesday}
                            onChange={(value) => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentStockTargetRatios: {
                                        ...siteDetails.reassortmentStockTargetRatios,
                                        wednesday: value,
                                    },
                                });
                            }}
                            min="0"
                            step="0.01"
                            required={true}
                        />
                        <TotemInput
                            label="Jeudi"
                            type="number"
                            value={reassortmentStockTargetRatios.thursday}
                            onChange={(value) => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentStockTargetRatios: {
                                        ...siteDetails.reassortmentStockTargetRatios,
                                        thursday: value,
                                    },
                                });
                            }}
                            min="0"
                            step="0.01"
                            required={true}
                        />
                        <TotemInput
                            label="Vendredi"
                            type="number"
                            value={reassortmentStockTargetRatios.friday}
                            onChange={(value) => {
                                setUpdatedSiteDetails({
                                    ...siteDetails,
                                    reassortmentStockTargetRatios: {
                                        ...siteDetails.reassortmentStockTargetRatios,
                                        friday: value,
                                    },
                                });
                            }}
                            min="0"
                            step="0.01"
                            required={true}
                        />
                    </InputsContainer>
                </div>
            </>
        </SectionContainer>
    );
};

const QRCodeContainer = styled.div`
    padding: 10px;
    background-color: white;
    height: 220px;
    width: 220px;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;

    & > :not(:first-child) {
        margin-left: 20px;
    }
`;

const Title = styled.div`
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
`;
