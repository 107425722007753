import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PAGES } from 'constants/pages';

import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { formatDateAsAnniversary, getNextMondayDate } from 'helpers/dateTimes';

const TMP_SITE_MENU_DETAILS = [
    ['Frigo 6 étagères', 'de0abfd9-8b37-4d1d-ba40-6902e50a58cc'],
    ['Frigo 5 étagères', '88837509-d58e-47df-a9ec-85746ca8e81f'],
    ['Frigo Mi-Boisson', '8568346c-54b5-4ab9-bcf9-b7df159ebd03'],
    ['Frigo TV5Monde', '7fa841ce-c9f7-49e5-bb98-c22d28ace48f'],
    ['Frigo Barclays', '6143df50-7fdc-49a7-b0e8-e24330ea5491'],
    ['Frigo Sensei Boisson / Petite Faim', '28ab3b54-a50a-49d0-83ad-22da93791c98'],
    ['Frigo Sensei Restauration ISS ADAMAS', 'a540cea2-5199-4d7d-aef1-41a467654df6'],
];

export const MicrostoreMenus = () => {
    const [selectedDate, setSelectedDate] = useState<Date>(getNextMondayDate());

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.menus} />
                </HeaderTitle>
            </Header>
            <ButtonContainer>
                <TotemDatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                        setSelectedDate(date || getNextMondayDate());
                    }}
                />
                {TMP_SITE_MENU_DETAILS.map(([siteName, siteId]) => (
                    <Link
                        key={siteId}
                        to={`/menusDetails/${siteId}/${formatDateAsAnniversary({
                            dateTime: selectedDate,
                            useNewFormat: true,
                        })}`}
                    >
                        <TotemPrimaryButton>{siteName}</TotemPrimaryButton>
                    </Link>
                ))}
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const ButtonContainer = styled.div`
    padding: 50px;
    display: flex;
    flex-direction: column;
    & > * {
        margin-top: 20px;
    }
`;
